import React from 'react';

import './App.css';
import { Page } from './components/page';

function App() {
  return (
    <div className="App">
      <Page />
    </div>
  );
}

export default App;
