const SobreNos = () => (
  <div>
    <h1>QUEM SOMOS?</h1>
    <br />
    <p>
      NA GE Beauty, Health & Wellness, o nosso objetivo é proporcionar soluções
      completas para que todas as mulheres possam ter uma vida mais saudável e
      feliz.
    </p>
    <p>
      Buscando inspiração na natureza e guiados pela ciência, os produtos GE são
      formulados com ingredientes de alta qualidade e fórmulas personalizadas
      para atender às necessidades únicas das mulheres em diferentes fases da
      vida.
    </p>
    <p>
      Os nossos produtos têm a missão de a ajudar a tornar-se na sua melhor
      versão, por dentro e por fora!
    </p>
  </div>
);

export default SobreNos;
