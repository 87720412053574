import FacebookSharpIcon from "@mui/icons-material/FacebookSharp";
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => (
  <footer
    style={{
      position: "fixed",
      bottom: 0,
      width: "100%",
      textAlign: "center",
      padding: "10px 0",
      backgroundColor: '#ffa952'
    }}
  >
    {/* Wrapper for text and icon */}
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p style={{ margin: 0 }}>{`© ${new Date().getFullYear()} | Garantequilibrio - Lda`}</p>
      <FacebookSharpIcon style={{ marginLeft: "1rem" }} />
      <InstagramIcon style={{ marginLeft: "0.3rem" }} />
    </div>
  </footer>
);

export default Footer;
