import SobreNos from "./sobre-nos";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Testemunhos from "./testemunhos";
import Produtos from "./produtos";
import FAQs from "./faqs";
import Footer from "../footer";
import Navbar from "../app-bar";

export const Page = () => {
  const navItems = [
    { label: 'Sobre Nós', path: '/sobrenos' },
    { label: 'Produtos', path: '/produtos' },
    { label: 'Testemunhos', path: '/testemunhos' },
    { label: 'FAQs', path: '/faqs' },
  ];

  return (
    <div
      style={{ backgroundColor: "#ffe79a", height: "100vh", width: "100vw" }}
    >
      <Router>
      <Navbar navItems={navItems} />
        <Routes>
          <Route path="/" element={<Produtos />} />
          <Route path="/sobrenos" element={<SobreNos />} />
          <Route path="/testemunhos" element={<Testemunhos />} />
          <Route path="/produtos" element={<Produtos />} />
          <Route path="/faqs" element={<FAQs />} />
        </Routes>
      </Router>
      <div>
      {/* Rest of your app components */}
    </div>
      <Footer />
    </div>
  );
};