// Navbar.tsx
import React from "react";
import { useState } from "react";
import "./appbar.css"; // Assuming you have some CSS for styling
import { Link } from "react-router-dom";

// Define the structure of the props, if needed
interface NavItem {
  label: string;
  path: string;
}

interface NavbarProps {
  navItems: NavItem[];
}

const Navbar: React.FC<NavbarProps> = ({ navItems }) => {
  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-logo">
          <img
            className="image-logo"
            src="https://i.ibb.co/BcvrmDr/logo.jpg"
            alt="logo"
          />  
        </div>
        <ul className={`navbar-links active`}>
          {navItems.map((item, index) => (
             <li key={index}>
             <Link to={item.path}>{item.label}</Link> {/* Use Link instead of a */}
           </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
